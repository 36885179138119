import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ref, onValue, off, update } from "firebase/database";
import { database } from "../utils/firebase.config";
import { FirebaseDB } from "../utils/constant";
import useStages from "../hooks/useStages";
export default function Stage() {
  const [stagesData, setStages] = useStages();
  const { stage } = useParams();
  const navigate = useNavigate();
  const activeStatDBref = ref(database, FirebaseDB.ACTIVE_STATE);
  useEffect(() => {
    onValue(activeStatDBref, (snapshot) => {
      const newData = snapshot.val();
      setStages(newData);
    });

    return () => {
      off(activeStatDBref);
    };
  }, []);

  useEffect(() => {
    if (
      !stage ||
      isNaN(+stage) ||
      +stage < 1 ||
      +stage > 4 ||
      !Number.isInteger(+stage)
    )
      navigate(`/stages/1`);
  }, [stage]);

  const images = useMemo(() => {
    return Object.keys(stagesData).map((key) => {
      const obj = stagesData[key];
      return (
        <img
          onClick={() => {
            if (!obj.state && key === `step_${stage}`) {
              update(
                activeStatDBref,
                Object.assign(stagesData, { [key]: { ...obj, state: 1 } })
              );
              console.log("Update");
            }
          }}
          key={key}
          src={`/assets/${obj.icon}${obj.state ? "_active" : ""}.png`}
        />
      );
    });
  }, [stagesData, stage]);
  return (
    <article className="stage-page">
      <header>PASSCODE</header>
      <section className="icons-container">{images}</section>
    </article>
  );
}
