import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./styles/main.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Stage from "./pages/Stage";
import Result from "./pages/Result";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/stages/:stage" element={<Stage />} />
      <Route path="/result" element={<Result />} />
      <Route path="/result-showonly" element={<Result showOnly={true} />} />
      <Route path="*" element={<Navigate to={"/stages/1"} />} />
    </Routes>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
