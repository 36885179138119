import React, { useState } from "react";
import useStages from "../hooks/useStages";
import { InputSwitch } from "primereact/inputswitch";
export default function Result({ showOnly }: { showOnly?: boolean }) {
  const [, , isAllActive, reset] = useStages();
  const [checked, setChecked] = useState(false);
  return (
    <article className="result-page-container">
      <header>
        <img src="/assets/GSK-logo.png" />
      </header>
      {(isAllActive || showOnly) && (
        <section className="swipe-container">
          <p>SWIPE TO UNLOCK</p>
          <InputSwitch
            checked={checked}
            onChange={(e: any) => {
              setChecked(e.value);
            }}
          />
        </section>
      )}
      {checked && (
        <section className="video-container">
          <video
            onEnded={() => {
              if (!showOnly) reset();
              setChecked(false);
            }}
            muted
            autoPlay
          >
            <source src={`/assets/video.mp4`} type="video/mp4" />
            Your browser does not support the videos.
          </video>
        </section>
      )}
    </article>
  );
}
