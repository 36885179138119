import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD_JPWPw3E5630F5pBLjZIIDsK3Naz8Fvc",
  authDomain: "unlock-game-1d034.firebaseapp.com",
  projectId: "unlock-game-1d034",
  storageBucket: "unlock-game-1d034.appspot.com",
  messagingSenderId: "352573057285",
  appId: "1:352573057285:web:622544f0dcd72e80b92f6c",
  measurementId: "G-25YTBPKQE8",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
