import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FirebaseDB, IconState } from "../utils/constant";
import { onValue, off, ref, update } from "@firebase/database";
import { database } from "../utils/firebase.config";

export default function useStages(): [
  { [key: string]: IconState },
  Function,
  boolean,
  Function
] {
  const [data, setData] = useState<{ [key: string]: IconState }>({});
  const activeStatDBref = ref(database, FirebaseDB.ACTIVE_STATE);
  const isAllActive = useMemo(() => {
    return (
      Object.values(data).length > 0 &&
      Object.values(data).every((p) => p.state)
    );
  }, [data]);
  const resetState = useCallback(() => {
    const initialState = Object.entries(data).reduce((a: any, b: any) => {
      a[b[0]] = { ...(b[1] as IconState), state: 0 };
      return a;
    }, {});
    update(activeStatDBref, initialState);
    setData(initialState);
  }, [data]);

  useEffect(() => {
    onValue(activeStatDBref, (snapshot) => {
      const newData = snapshot.val();
      setData(newData);
    });

    return () => {
      off(activeStatDBref);
    };
  }, []);
  return [data, setData, isAllActive, resetState];
}
